import { template as template_86f085b9278a48edac9bc2e073c5884e } from "@ember/template-compiler";
const WelcomeHeader = template_86f085b9278a48edac9bc2e073c5884e(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;

import { template as template_952494a91c54416898f0d79ec900e549 } from "@ember/template-compiler";
const FKControlMenuContainer = template_952494a91c54416898f0d79ec900e549(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;

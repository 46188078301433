import { template as template_b61fa3d7d61b41ba84a480916dcf3c43 } from "@ember/template-compiler";
const FKLabel = template_b61fa3d7d61b41ba84a480916dcf3c43(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;

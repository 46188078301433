import { template as template_9a451eb3f3994598b74b0188e7d3051a } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_9a451eb3f3994598b74b0188e7d3051a(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;

import { template as template_a500f82fcaab42b9b609d1e536e4b364 } from "@ember/template-compiler";
const SidebarSectionMessage = template_a500f82fcaab42b9b609d1e536e4b364(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
